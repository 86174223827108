










import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import DashboardWelcome from '@/components/dashboard/DashboardWelcome.vue'
import DashboardNews from '@/components/dashboard/DashboardNews.vue'
import DashboardProgress from '@/components/dashboard/DashboardProgress.vue'
import DashboardEvents from '@/components/dashboard/DashboardEvents.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
@Component({
  components: {
    DashboardEvents,
    DashboardProgress,
    DashboardNews,
    DashboardWelcome
  }
})
export default class Dashboard extends Vue {
  get dashboardWelcomeRight (): boolean {
    return hasRight(Rights.DASHBOARD_WELCOME)
  }

  get dashboardEventsRight (): boolean {
    return hasRight(Rights.DASHBOARD_EVENTS)
  }

  get dashboardNewsRights (): boolean {
    // || other news rights
    return (
      hasRight(Rights.DASHBOARD_NEWS_NEW_EVENTS) ||
      hasRight(Rights.DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS) ||
      hasRight(Rights.DASHBOARD_NEWS_NEW_JOBADS) ||
      hasRight(Rights.DASHBOARD_NEWS_NEW_MESSAGES) ||
      hasRight(Rights.DASHBOARD_ADMINISTRATION_AND_TODOS) ||
      hasRight(Rights.DASHBOARD_TODOS)
    )
  }

  get dashboardProgressRights (): boolean {
    // company relevant rights
    const companyProfileProgressRights = hasRight(Rights.DASHBOARD_PROGRESS_COMPANY) && hasRight(Rights.COMPANY_OWN_PROFILE_READ)
    const companyJobAdStatisticsRights = hasRight(Rights.DASHBOARD_PROGRESS_JOB_STATISTICS) && hasRight(Rights.JOB_AD_OWN_READ)

    // talent relevant rights
    const talentCompetencesRight = hasRight(Rights.DASHBOARD_PROGRESS_COMPETENCE_METERING)
    const talentKodeStatusRights = hasRight(Rights.DASHBOARD_PROGRESS_COMPETENCE_ADVANCEMENT) && hasRight(Rights.KODE_TEST_READ)

    return companyProfileProgressRights || companyJobAdStatisticsRights || talentCompetencesRight || talentKodeStatusRights
  }
}
