




















































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import DashboardCollapse from '@/components/dashboard/DashboardCollapse.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { Action, State } from 'vuex-class'
import {
  GET_DASHBOARD_COMPETENCES,
  GET_DASHBOARD_KODE_STATUS,
  GET_DASHBOARD_COMPANY_PROGRESS,
  GET_DASHBOARD_JOB_ADS, GET_DASHBOARD_MENTORING_PROGRESS, GET_DASHBOARD_WELCOME
} from '@/store/dashboard/actions'
import { CompanyProfile } from '@/store/company/types'
import { JobAdType } from '@/store/jobAd/types'
import { CompetenceImprovement } from '@/store/kode/types'
import SelectedCompetences from '@/components/kode/SelectedCompetences.vue'
import InfoBox from '@/components/hszg/InfoBox.vue'
import ProgressSteps from '@/components/common/ProgressSteps.vue'
import { ProgressStep } from '@/store/types'
import KodeStatus from '@/common/kodeStatus'
import Enums from '@/common/Enums'
import Competence from '@/components/hszg/Competence.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { DashboardMentoringType, DashboardWelcomeType } from '@/store/dashboard/types'
@Component({
  components: { LoadingSpinner, Competence, ProgressSteps, InfoBox, SelectedCompetences, DashboardCollapse },
  name: 'DashboardProgress'
})
// @vuese
// Dashboard component for user progress
// @group DASHBOARD
export default class DashboardProgress extends Vue {
  @State('showDesktopVersion') showDesktopVersion: boolean

  @Action(GET_DASHBOARD_COMPETENCES)
  public getDashboardCompetences: () => Promise<CompetenceImprovement>

  @Action(GET_DASHBOARD_KODE_STATUS)
  public getDashboardKodeStatus: () => Promise<string>

  @Action(GET_DASHBOARD_COMPANY_PROGRESS)
  public getDashboardCompanyProgress: () => Promise<CompanyProfile>

  @Action(GET_DASHBOARD_JOB_ADS)
  public getDashboardJobAds: () => Promise<Array<JobAdType>>

  @Action(GET_DASHBOARD_MENTORING_PROGRESS)
  public getDashboardMentoringProgress: () => Promise<DashboardMentoringType>

  @Action(GET_DASHBOARD_WELCOME)
  public getDashboardWelcome: () => Promise<DashboardWelcomeType>

  errorMessage = ''
  showErrorMessage = false

  loading = {
    competences: false,
    kodeStatus: false,
    companyProfile: false,
    jobAds: false,
    mentoringProgress: false,
    welcome: false
  }

  competences: CompetenceImprovement = {}
  kodeStatus = ''
  companyProfile: CompanyProfile = {}
  jobAds: JobAdType[] = []
  mentoringProgress: DashboardMentoringType = {}
  profileData: DashboardWelcomeType = {}

  mounted (): void {
    if (this.talentCompetencesRight) this.loadCompetences()
    if (this.talentKodeStatusRights) this.loadKodeStatus()
    if (this.companyProfileProgressRights) this.loadCompanyProfile()
    if (this.companyJobAdStatisticsRights) this.loadJobAds()
    if (this.mentoringProgressRight) this.loadMentoringProgress()
    this.loadWelcome()
  }

  loadCompetences (): void {
    this.loading.competences = true
    this.getDashboardCompetences().then(data => {
      this.competences = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.competences = false
    })
  }

  loadKodeStatus (): void {
    this.loading.kodeStatus = true
    this.getDashboardKodeStatus().then(data => {
      this.kodeStatus = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.kodeStatus = false
    })
  }

  loadCompanyProfile (): void {
    this.loading.companyProfile = true
    this.getDashboardCompanyProgress().then(data => {
      this.companyProfile = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.companyProfile = false
    })
  }

  loadJobAds (): void {
    this.loading.jobAds = true
    this.getDashboardJobAds().then(data => {
      this.jobAds = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.jobAds = false
    })
  }

  loadMentoringProgress (): void {
    this.loading.mentoringProgress = true
    this.getDashboardMentoringProgress().then(data => {
      this.mentoringProgress = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.mentoringProgress = false
    })
  }

  loadWelcome (): void {
    this.loading.welcome = true
    this.getDashboardWelcome().then(data => {
      this.profileData = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.welcome = false
    })
  }

  get kodeProgress (): ProgressStep[] {
    return [
      { index: 0, status: KodeStatus.DATA, text: this.$i18n.t('kode-metering.profile-complete').toString() },
      { index: 1, status: KodeStatus.APPLY, text: this.$i18n.t('kode-metering.metering-requested').toString() },
      { index: 2, status: KodeStatus.WAITING, text: this.$i18n.t('kode-metering.metering-done').toString() },
      { index: 3, status: KodeStatus.FINISH, text: this.$i18n.t('kode-metering.results-received').toString() }
    ]
  }

  get companyProfilePercentage (): number {
    if (this.companyProfile) {
      this.companyProfile.title = ''
      this.companyProfile.supplement = ''

      // minus 2 for account and profile id which are always present
      let filled = Object.values(this.companyProfile).filter(el => el).length - 2
      if (filled < 0) filled = 0

      // minus 4 for mobile, region, videoLink and fallbackMail which can not be filled
      // and minus 2 for title and supplement which are okay when empty
      let missing = Object.values(this.companyProfile).filter(el => !el).length - 4 - 2
      if (missing < 0) missing = 0

      let percentage = Math.round(filled / (filled + missing) * 100)
      if (!percentage || percentage < 0) percentage = 0
      if (percentage > 100) percentage = 100
      return percentage
    } else return 0
  }

  get companyJobAdStatistics (): number[] {
    const incomplete = this.jobAds.filter(job => job.status === Enums.JOB_AD_STATUS_INCOMPLETE).length
    const completeNotPublished = this.jobAds.filter(job => job.status === Enums.JOB_AD_STATUS_COMPLETED_NOT_PUBLISHED).length
    const published = this.jobAds.filter(job => job.status === Enums.JOB_AD_STATUS_PUBLISHED).length
    const sum = incomplete + completeNotPublished + published
    return [sum, incomplete, completeNotPublished, published]
  }

  get activationStatusTalent (): string | undefined {
    return this.profileData.studentDTO?.activationStatus
  }

  get activationStatusCompany (): string | undefined {
    return this.profileData.companyDTO?.activationStatus
  }

  get talentCompetencesRight (): boolean {
    return hasRight(Rights.DASHBOARD_PROGRESS_COMPETENCE_METERING)
  }

  get talentKodeStatusRights (): boolean {
    return hasRight(Rights.DASHBOARD_PROGRESS_COMPETENCE_ADVANCEMENT) && hasRight(Rights.KODE_TEST_READ)
  }

  get companyProfileProgressRights (): boolean {
    return hasRight(Rights.DASHBOARD_PROGRESS_COMPANY) && hasRight(Rights.COMPANY_OWN_PROFILE_READ)
  }

  get companyJobAdStatisticsRights (): boolean {
    return hasRight(Rights.DASHBOARD_PROGRESS_JOB_STATISTICS) && hasRight(Rights.JOB_AD_OWN_READ)
  }

  get mentoringProgressRight (): boolean {
    return hasRight(Rights.DASHBOARD_PROGRESS_MENTORING)
  }
}
