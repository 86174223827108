
























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
// Dashboard component for usage in DashboardNews
// @group DASHBOARD
export default class DashboardNewsItem extends Vue {
  @Prop({ required: true })
  title: string

  @Prop({ required: false })
  subtitle: string

  @Prop({ required: false })
  routerLink: string

  @Prop({ required: false })
  linkText: string
}
