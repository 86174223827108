




























































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import {
  GET_DASHBOARD_NEWS_NEW_JOB_ADS,
  GET_DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS,
  GET_DASHBOARD_NEWS_NEW_OWN_EVENTS,
  GET_DASHBOARD_NEW_MESSAGES,
  GET_DASHBOARD_KODE_APPLICATIONS,
  GET_DASHBOARD_OPEN_INQUIRIES,
  GET_DASHBOARD_OWN_OPEN_MENTOR_REQUESTS,
  GET_DASHBOARD_OWN_OPEN_INQUIRIES,
  GET_DASHBOARD_OPEN_ACTIVATION_REQUESTS
} from '@/store/dashboard/actions'
import { Event } from '@/store/event/types'
import DashboardCollapse from '@/components/dashboard/DashboardCollapse.vue'
import {
  DashboardNewContent, DashboardOpenActivationRequestsType, DashboardOpenInquiriesType,
  DashboardOwnOpenInquiriesType,
  DashboardOwnOpenMentorRequestsType
} from '@/store/dashboard/types'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { Messages } from '@/store/user/types'
import DashboardNewsItem from '@/components/dashboard/DashboardNewsItem.vue'
@Component({
  components: { DashboardNewsItem, DashboardCollapse },
  name: 'DashboardNews'
})
// @vuese
// Dashboard component displaying the news. Use DashboardNewsItem component to display new items
// @group DASHBOARD
export default class DashboardNews extends Vue {
  @Action(GET_DASHBOARD_NEWS_NEW_OWN_EVENTS)
  public getDashboardNewsNewOwnEvents: () => Promise<Array<Event>>

  @Action(GET_DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS)
  public getDashboardNewsNewCompetenceMeterings: () => Promise<DashboardNewContent>

  @Action(GET_DASHBOARD_NEWS_NEW_JOB_ADS)
  public getDashboardNewsNewJobAds: () => Promise<DashboardNewContent>

  @Action(GET_DASHBOARD_KODE_APPLICATIONS)
  public getDashboardKodeApplications: () => Promise<DashboardNewContent>

  @Action(GET_DASHBOARD_NEW_MESSAGES)
  public getDashboardNewMessages: () => Promise<Array<Messages>>

  @Action(GET_DASHBOARD_OPEN_INQUIRIES)
  public getDashboardOpenInquiries: () => Promise<DashboardOwnOpenInquiriesType>

  @Action(GET_DASHBOARD_OWN_OPEN_MENTOR_REQUESTS)
  public getDashboardOwnOpenMentorRequests: () => Promise<DashboardOwnOpenMentorRequestsType>

  @Action(GET_DASHBOARD_OWN_OPEN_INQUIRIES)
  public getDashboardOwnOpenInquiries: () => Promise<DashboardOpenInquiriesType>

  @Action(GET_DASHBOARD_OPEN_ACTIVATION_REQUESTS)
  public getDashboardOpenActivationRequests: () => Promise<DashboardOpenActivationRequestsType>

  newsAvailable = false

  errorMessage = ''
  showErrorMessage = false

  loading = {
    newOwnEvents: false,
    newJobAds: false,
    newCompetenceMeterings: false,
    newMessages: false,
    kodeApplications: false,
    openInquiries: false,
    ownOpenMentorRequests: false,
    ownOpenInquiries: false,
    openActivationRequests: false
  }

  loaded = {
    newOwnEvents: false,
    newJobAds: false,
    newCompetenceMeterings: false,
    newMessages: false,
    kodeApplications: false,
    openInquiries: false,
    ownOpenMentorRequests: false,
    ownOpenInquiries: false,
    openActivationRequests: false
  }

  newOwnEvents: Event[] = []
  newMessages: Messages[] = []
  newJobAds: DashboardNewContent = { number: 0 }
  newCompetenceMeterings: DashboardNewContent = { number: 0 }
  kodeApplications: DashboardNewContent = { number: 0 }
  openInquiries: DashboardOpenInquiriesType = {
    sentToCompany: 0,
    sentToMentor: 0,
    sentToTalent: 0
  }

  ownOpenMentorRequests: DashboardOwnOpenMentorRequestsType = {
    sent: 0,
    received: 0
  }

  ownOpenInquiries: DashboardOwnOpenInquiriesType = {
    sentToCompany: 0,
    sentToTalent: 0,
    receivedFromCompany: 0,
    receivedFromTalent: 0
  }

  openActivationRequests: DashboardOpenActivationRequestsType = {
    talents: 0,
    companies: 0
  }

  mounted (): void {
    if (hasRight(Rights.DASHBOARD_NEWS_NEW_EVENTS)) this.loadNewEvents()
    if (hasRight(Rights.DASHBOARD_NEWS_NEW_COMPETENCE_METERINGS)) this.loadNewCompetenceMeterings()
    if (hasRight(Rights.DASHBOARD_NEWS_NEW_JOBADS)) this.loadNewJobAds()
    if (hasRight(Rights.DASHBOARD_NEWS_NEW_MESSAGES)) this.loadNewMessages()
    if (hasRight(Rights.DASHBOARD_NEWS_NEW_MESSAGES)) this.loadKodeApplications()
    if (hasRight(Rights.DASHBOARD_ADMINISTRATION_AND_TODOS)) this.loadOpenInquiries()
    if (hasRight(Rights.DASHBOARD_ADMINISTRATION_AND_TODOS)) this.loadOpenActivationRequests()
    if (hasRight(Rights.DASHBOARD_TODOS)) this.loadOwnOpenMentorRequests()
    if (hasRight(Rights.DASHBOARD_TODOS)) this.loadOwnOpenInquiries()
  }

  getHasRight (right: string): boolean {
    return hasRight(right)
  }

  get Rights (): object {
    return Rights
  }

  get colSize (): number {
    return [
      (this.ownOpenInquiries?.receivedFromCompany || this.ownOpenInquiries?.sentToCompany),
      (this.ownOpenInquiries?.receivedFromTalent || this.ownOpenInquiries?.sentToTalent),
      this.kodeApplications?.number,
      this.ownOpenMentorRequests?.received,
      this.ownOpenMentorRequests?.sent,
      (this.newMessages?.length > 0),
      (this.newOwnEvents?.length > 0),
      (this.newJobAds.number > 0),
      (this.newCompetenceMeterings.number > 0)
    ].filter(o => o).length
  }

  loadNewEvents (): void {
    this.loading.newOwnEvents = true
    this.getDashboardNewsNewOwnEvents().then(data => {
      this.loaded.newOwnEvents = true
      this.newOwnEvents = data
      if (this.newOwnEvents.length > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.newOwnEvents = false
    })
  }

  loadNewCompetenceMeterings (): void {
    this.loading.newCompetenceMeterings = true
    this.getDashboardNewsNewCompetenceMeterings().then(data => {
      this.loaded.newCompetenceMeterings = true
      this.newCompetenceMeterings = data
      if (this.newCompetenceMeterings?.number > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.newCompetenceMeterings = false
    })
  }

  loadNewJobAds (): void {
    this.loading.newJobAds = true
    this.getDashboardNewsNewJobAds().then(data => {
      this.loaded.newJobAds = true
      this.newJobAds = data
      if (this.newJobAds?.number > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.newJobAds = false
    })
  }

  loadNewMessages (): void {
    this.loading.newMessages = true
    this.getDashboardNewMessages().then(data => {
      this.loaded.newMessages = true
      this.newMessages = data
      if (this.newMessages?.length > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.newMessages = false
    })
  }

  loadKodeApplications (): void {
    this.loading.kodeApplications = true
    this.getDashboardKodeApplications().then(data => {
      this.loaded.kodeApplications = true
      this.kodeApplications = data
      if (this.kodeApplications?.number > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.kodeApplications = false
    })
  }

  loadOpenInquiries (): void {
    this.loading.openInquiries = true
    this.getDashboardOpenInquiries().then(data => {
      this.loaded.openInquiries = true
      this.openInquiries = data
      this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.openInquiries = false
    })
  }

  loadOwnOpenMentorRequests (): void {
    this.loading.ownOpenMentorRequests = true
    this.getDashboardOwnOpenMentorRequests().then(data => {
      this.loaded.ownOpenMentorRequests = true
      this.ownOpenMentorRequests = data
      if (Object.values(this.ownOpenMentorRequests).reduce((t, a) => t + a) > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.ownOpenMentorRequests = false
    })
  }

  loadOwnOpenInquiries (): void {
    this.loading.ownOpenInquiries = true
    this.getDashboardOwnOpenInquiries().then(data => {
      this.loaded.ownOpenInquiries = true
      this.ownOpenInquiries = data
      if (Object.values(this.ownOpenInquiries).reduce((t, a) => t + a) > 0) this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.ownOpenInquiries = false
    })
  }

  loadOpenActivationRequests (): void {
    this.loading.openActivationRequests = true
    this.getDashboardOpenActivationRequests().then(data => {
      this.loaded.openActivationRequests = true
      this.openActivationRequests = data
      this.newsAvailable = true
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.openActivationRequests = false
    })
  }
}
