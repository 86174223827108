


























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import EventSearchResult from '@/components/search-results/EventSearchResult.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { Event } from '@/store/event/types'
import { Action } from 'vuex-class'
import { BOOKMARK_EVENT } from '@/store/event/actions.type'
import { SimpleServerResponse } from '@/store/types'

@Component({
  components: { EventSearchResult, ResultsPage }
})
// Dashboard component used in DashboardEvents
// @group DASHBOARD
export default class DashboardEventTab extends Vue {
  @Action(BOOKMARK_EVENT)
  public bookmarkEvent: (event: Event) => Promise<SimpleServerResponse>

  // title of the event tab
  @Prop({ required: true })
  title: string

  // array of events
  @Prop({ required: true })
  events: Event[]

  get bookmarkable (): boolean {
    return hasRight(Rights.BOOKMARK_EVENT)
  }

  onBookmark (event: Event): void {
    let feedbackHeadline = ''
    let error = false
    this.$set(event, 'bookmark', !event.bookmark)
    this.bookmarkEvent(event).then(res => {
      feedbackHeadline = this.$t((event.bookmark ? 'events.bookmark.' : 'events.unbookmark.') + res.message).toString()
    }).catch((res) => {
      error = true
      feedbackHeadline = this.$t((event.bookmark ? 'events.bookmark.' : 'events.unbookmark.') + res.message).toString()
    }).finally(() => {
      this.$root.$emit('alert', feedbackHeadline, '', error)
    })
  }
}
