




























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_DASHBOARD_EVENTS } from '@/store/dashboard/actions'
import { DashboardEventsType } from '@/store/dashboard/types'
import EventSearchResult from '@/components/search-results/EventSearchResult.vue'
import DashboardCollapse from '@/components/dashboard/DashboardCollapse.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import DateOutput from '@/components/events/DateOutput.vue'
import DashboardEventTab from '@/components/dashboard/DashboardEventTab.vue'
@Component({
  components: { DashboardEventTab, DateOutput, SearchResult, ResultsPage, DashboardCollapse, EventSearchResult },
  name: 'DashboardEvents'
})
// @vuese
// Dashboard component for events
// @group DASHBOARD
export default class DashboardEvents extends Vue {
  @Action(GET_DASHBOARD_EVENTS)
  public getDashboardEvents: () => Promise<DashboardEventsType>

  eventsData: DashboardEventsType = { }

  errorMessage = ''
  showErrorMessage = false
  isLoading = false

  get bookmarkedEventsExistent (): boolean {
    return this.eventsData.bookmarkedEvents !== undefined && this.eventsData.bookmarkedEvents?.length > 0
  }

  get eventsOfCompetencesExistent (): boolean {
    return this.eventsData.eventsOfCompetences !== undefined && this.eventsData.eventsOfCompetences?.length > 0
  }

  get ownEventsExistent (): boolean {
    return this.eventsData.ownEvents !== undefined && this.eventsData.ownEvents?.length > 0
  }

  mounted (): void {
    this.loadEvents()
  }

  loadEvents (): void {
    this.isLoading = true
    this.getDashboardEvents().then(data => {
      this.eventsData = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.isLoading = false
    })
  }
}
