


























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { GET_DASHBOARD_PROFILE_PICTURE, GET_DASHBOARD_WELCOME } from '@/store/dashboard/actions'
import { DashboardWelcomeType } from '@/store/dashboard/types'
import { FileDto } from '@/store/types'
@Component({
  components: {},
  name: 'DashboardWelcome'
})
// @vuese
// Dashboard component welcoming the user (hello name, image)
// @group DASHBOARD
export default class DashboardWelcome extends Vue {
  @Action(GET_DASHBOARD_WELCOME)
  public getDashboardWelcome: () => Promise<DashboardWelcomeType>

  @Action(GET_DASHBOARD_PROFILE_PICTURE)
  public getDashboardProfilePicture: (profileType: string) => Promise<FileDto>

  welcomeData: DashboardWelcomeType = {}

  loading = {
    welcome: false,
    profilePicture: false
  }

  errorMessage = ''
  showErrorMessage = false

  profilePictures: { type: string; picture: FileDto | null }[] = [
    { type: 'STUDENT', picture: null },
    { type: 'COMPANY', picture: null },
    { type: 'EMPLOYEE', picture: null },
    { type: 'MENTOR', picture: null }
  ]

  mounted (): void {
    this.loadWelcome()
    this.loadProfilePicture()
  }

  loadWelcome (): void {
    this.loading.welcome = true
    this.getDashboardWelcome().then(data => {
      this.welcomeData = data
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.loading.welcome = false
    })
  }

  loadProfilePicture (): void {
    const promises = this.profilePictures.map(pic => {
      return this.getDashboardProfilePicture(pic.type).then(data => {
        pic.picture = data
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      })
    })
    this.loading.profilePicture = true
    Promise.all(promises).finally(() => {
      this.loading.profilePicture = false
    })
  }

  get welcomeString (): string {
    let firstName = ''
    if (this.welcomeData.studentDTO?.firstname) firstName = this.welcomeData.studentDTO?.firstname
    else if (this.welcomeData.companyDTO?.firstname) firstName = this.welcomeData.companyDTO?.firstname
    else if (this.welcomeData.employeeDTO?.firstname) firstName = this.welcomeData.employeeDTO?.firstname
    else if (this.welcomeData.mentorDTO?.firstname) firstName = this.welcomeData.mentorDTO?.firstname
    let lastName = ''
    if (this.welcomeData.studentDTO?.lastname) lastName = this.welcomeData.studentDTO?.lastname
    else if (this.welcomeData.companyDTO?.lastname) lastName = this.welcomeData.companyDTO?.lastname
    else if (this.welcomeData.employeeDTO?.lastname) lastName = this.welcomeData.employeeDTO?.lastname
    else if (this.welcomeData.mentorDTO?.lastname) lastName = this.welcomeData.mentorDTO?.lastname
    return [firstName, lastName].join(' ')
  }

  otherPictureIdentical (index: number): boolean {
    let isIdentical = false
    for (let i = 1; i < index; i++) {
      if (this.profilePictures[index].picture?.base64Content === this.profilePictures[i].picture?.base64Content) {
        isIdentical = true
      }
    }
    return isIdentical
  }
}
